import React from 'react'
import './Loading.css'

function LoadingAnimation() {
	return (
		<div className='Animation'>
			<div className='Animation-container'>
				<div className='Animation-container-one' />
				<div className='Animation-container-two' />
				<div className='Animation-container-three' />
				<div className='Animation-container-four' />
				<div className='Animation-container-five' />
				<div className='Animation-container-six' />
				<div className='Animation-container-seven' />
			</div>
		</div>
	)
}
export default LoadingAnimation
