// @ts-nocheck
import {
	Box,
	Container,
	Grid,
	makeStyles,
	Typography,
	withStyles,
} from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { ColumnToRow, Item } from '@mui-treasury/components/flex'
import {
	CategoryItem,
	CategoryProvider,
} from '@mui-treasury/components/menu/category'
import { NavItem, NavMenu } from '@mui-treasury/components/menu/navigation'
import { useMagCategoryMenuStyles } from '@mui-treasury/styles/categoryMenu/mag'
import { usePlainNavigationMenuStyles } from '@mui-treasury/styles/navigationMenu/plain'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../../assets/real-estate-equals-ormica-logo.svg'
import DividerCommon from '../sections/DividerCommon'
import { SocialIcons } from './SocialIcons'
import SubscribeInput from './SubscribeInput'

const darkTheme = createTheme({ palette: { type: 'dark' } })
const useStyles = makeStyles(({ typography }) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		maxHeight: '100vh',
	},
	newsletterText: {
		color: '#fff',
		fontSize: '0.875rem',
		textTransform: 'uppercase',
		letterSpacing: '0.05rem',
	},
	divider: {
		height: 2,
		margin: '-1px 0',
	},
	top: {
		width: '100vw',
		borderRadius: 0,
	},
	overlay: {
		'position': 'absolute',
		'top': -2,
		'left': -2,
		'bottom': -2,
		'right': -2,
		'filter': 'blur(2px) brightness(50%)',
		'borderRadius': 0,
		'& img': {
			width: '100%',
			borderRadius: 0,
			height: '100%',
			objectFit: 'cover',
		},
	},
	info: {
		...typography.caption,
		marginTop: 8,
		color: 'rgba(243, 248, 252, 0.5)',
	},
	box: ({ inactive }) => ({
		boxShadow: '0 0 20px 0 rgba(0,0,0,0.12)',
		transition: '0.3s',
		...(!inactive && {
			'&:hover': {
				transform: 'translateY(-3px)',
				boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
			},
		}),
	}),
	footer: {
		marginTop: 'auto',
		backgroundColor: 'inherit',
		bottom: 0,
	},
	legalLink: {
		'textTransform': 'uppercase',
		'textDecoration': 'none',
		'fontSize': '0.875rem',

		'color': 'rgba(243,248,252,0.7)',
		'&:hover': {
			color: '#f3f8fc',
		},
	},
	footerText: {
		fontSize: '0.875rem',
		textTransform: 'uppercase',
		letterSpacing: '0.02rem',
		fontFamily: 'calibri, sans-serif',
		fontWeight: 300,
		fontStyle: 'normal',
	},
}))

function Footer(props) {
	const classes = useStyles()
	const { openRegister, openLogin } = props

	const menuItems = [
		{
			name: 'REGISTER',
			onClick: openRegister,
		},
		{
			name: 'LOGIN',
			onClick: openLogin,
		},
	]

	return (
		<footer>
			<ThemeProvider theme={darkTheme}>
				<Box
					width={'100vw'}
					style={{
						background: 'linear-gradient(to top, #002A51, 75%, #005FAD)',
					}}>
					<Box py={6} className={classes.top} position={'relative'}>
						<div className={classes.overlay}>
							<img
								src={
									'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2251&q=80'
								}
								loading='lazy'
								alt={
									'a laptop and mouse perched atop a wooden desk with an empty office chair parked nearby'
								}
								width='2251'
								height='1501'
							/>
						</div>
						<SubscribeInput />
					</Box>
					<Box px={6} py={6} className={classes.middle}>
						<Container disableGutters>
							<Grid
								container
								direction='row'
								justifyContent='center'
								alignItems='center'>
								<Grid item xs={12} sm={3}>
									<Grid
										container
										direction='column'
										justifyContent='space-evenly'
										alignItems='center'>
										<Grid item>
											<Link to='/'>
												<Box
													px={4}
													component={'img'}
													width='100%'
													height={'auto'}
													src={logo}
													className={classes.box}
													alt={'Ormica Company Logo'}
												/>
											</Link>
										</Grid>
										<Grid item>
											<Box py={2}>
												<SocialIcons />
											</Box>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} sm={2} />
								<Grid item xs={12} sm={7}>
									<Grid
										container
										direction='row'
										justifyContent='space-around'
										alignItems='flex-end'
										spacing={2}>
										<Grid item xs={6} sm={3}>
											<ColumnToRow
												at={'md'}
												columnStyle={{ alignItems: 'center' }}
												rowStyle={{ alignItems: 'unset' }}>
												<Item grow ml={-2} shrink={0}>
													<Box py={1} textAlign={{ xs: 'center', md: 'left' }}>
														<CategoryProvider useStyles={useMagCategoryMenuStyles}>
															<Link to='/solutions'>
																<CategoryItem>Buy</CategoryItem>
															</Link>
															<Link to='/solutions'>
																<CategoryItem>Sell</CategoryItem>
															</Link>
															<Link to='/solutions'>
																<CategoryItem>Partner</CategoryItem>
															</Link>
														</CategoryProvider>
													</Box>
												</Item>
											</ColumnToRow>
										</Grid>
										<Grid item xs={6} sm={3}>
											<ColumnToRow
												at={'md'}
												columnStyle={{ alignItems: 'center' }}
												rowStyle={{ alignItems: 'unset' }}>
												<Item grow ml={-2} shrink={0}>
													<Box py={1} textAlign={{ xs: 'center', md: 'left' }}>
														<CategoryProvider useStyles={useMagCategoryMenuStyles}>
															<Link to='/about'>
																<CategoryItem>About</CategoryItem>
															</Link>
															<Link to='/about'>
																<CategoryItem>Mission</CategoryItem>
															</Link>
															<Link to='/about'>
																<CategoryItem>Team</CategoryItem>
															</Link>
														</CategoryProvider>
													</Box>
												</Item>
											</ColumnToRow>
										</Grid>
										<Grid item xs={6} sm={3}>
											<ColumnToRow
												at={'md'}
												columnStyle={{ alignItems: 'center' }}
												rowStyle={{ alignItems: 'unset' }}>
												<Item grow ml={-2} shrink={0}>
													<Box py={1} textAlign={{ xs: 'center', md: 'left' }}>
														<CategoryProvider useStyles={useMagCategoryMenuStyles}>
															<Link to='/resources'>
																<CategoryItem>FAQ</CategoryItem>
															</Link>
															<Link to='/resources'>
																<CategoryItem>Tools</CategoryItem>
															</Link>
															<Link to='/resources'>
																<CategoryItem>Resources</CategoryItem>
															</Link>
														</CategoryProvider>
													</Box>
												</Item>
											</ColumnToRow>
										</Grid>
										<Grid item xs={6} sm={3}>
											<ColumnToRow
												at={'md'}
												columnStyle={{ alignItems: 'center' }}
												rowStyle={{ alignItems: 'unset' }}>
												<Item grow ml={-2} shrink={0}>
													<Box py={1} textAlign={{ xs: 'center', md: 'left' }}>
														<CategoryProvider useStyles={useMagCategoryMenuStyles}>
															<Link to='/contact'>
																<CategoryItem>Contact</CategoryItem>
															</Link>
															<Link to='/contact'>
																<CategoryItem>Partners</CategoryItem>
															</Link>
															<Link to='/contact'>
																<CategoryItem>Careers</CategoryItem>
															</Link>
														</CategoryProvider>
													</Box>
												</Item>
											</ColumnToRow>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Container>
					</Box>
					<Container disableGutters>
						<DividerCommon className={classes.divider} />
					</Container>
					<div className={classes.root}>
						<Box px={6} py={3} className={classes.footer}>
							<Container disableGutters>
								<ColumnToRow
									at={'md'}
									columnStyle={{ alignItems: 'center' }}
									rowStyle={{ alignItems: 'unset' }}>
									<Item grow ml={-2} shrink={0}>
										<Box py={1} textAlign={{ xs: 'center', md: 'left' }}>
											<NavMenu useStyles={usePlainNavigationMenuStyles}>
												<ColumnToRow at={'sm'}>
													<a className={classes.legalLink} href='/sitemap.xml'>
														<NavItem className={classes.legalLink}>Sitemap</NavItem>
													</a>
													{menuItems.map((element) => (
														<NavItem
															size='small'
															className={classes.legalLink}
															onClick={element.onClick}
															key={element.name}>
															{element.name}
														</NavItem>
													))}
												</ColumnToRow>
											</NavMenu>
										</Box>
									</Item>
									<Item grow mr={-2} shrink={0}>
										<Box
											pt={2}
											textAlign={{ xs: 'center', md: 'right' }}
											marginRight={{ xs: '0px', md: '50px' }}>
											<Typography
												className={classes.footerText}
												color='textSecondary'>
												©2022 Ormica, LLC. All rights reserved. Made with React.js
												and 🖤 by{' '}
												<a
													className={classes.legalLink}
													href='https://safethishome.com'
													target='_blank'
													rel='noopener noreferrer nofollow'>
													STHCoders
												</a>
												.
											</Typography>
										</Box>
									</Item>
								</ColumnToRow>
							</Container>
						</Box>
					</div>
				</Box>
			</ThemeProvider>
		</footer>
	)
}

Footer.propTypes = {
	openRegister: PropTypes.func.isRequired,
	openLogin: PropTypes.func.isRequired,
}
export default withStyles(makeStyles, { withTheme: true })(memo(Footer))
