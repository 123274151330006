// @ts-nocheck
import axios from 'axios'

export default function subscribeCaptcha() {
	grecaptcha.enterprise.ready(async () => {
		await grecaptcha.enterprise
			.execute(`${process.env.REACT_APP_RECAPTCHA_SITEKEY}`, { action: 'SUBSCRIBE' })

			.then((token) => {
				axios({
					method: 'POST',
					url: '/api/assesser',
					data: `token=${token}`,
				})
			})
			.catch((err) => {
				axios({
					method: 'POST',
					url: '/api/assesser/:error',
					data: 'The subscribeCaptcha call failed because' + err,
				})
			})
	})
}
