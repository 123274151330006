// @ts-nocheck
import React, { Fragment, useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import { Snackbar, Button, Typography, Box, withStyles } from '@material-ui/core'
import fetchIpData from '../../../utils/fetchIpData'

const styles = (theme) => ({
	snackbarContent: {
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		backgroundColor: 'linear-gradient(to right top, #FFF, 50%, #F47721 )',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundAttachment: 'fixed',
		color: theme.palette.primary.dark,
		fontWeight: 'bold',
	},
})

const europeanCountryCodes = [
	'BE',
	'BG',
	'AT',
	'CY',
	'CZ',
	'DE',
	'DK',
	'EE',
	'ES',
	'FI',
	'FR',
	'GB',
	'GR',
	'HR',
	'HU',
	'IE',
	'IT',
	'LT',
	'LU',
	'LV',
	'MT',
	'NL',
	'PO',
	'PT',
	'RO',
	'SE',
	'SI',
	'SK',
]

function CookieConsent(props) {
	// eslint-disable-next-line react/prop-types
	const { classes, handleCookieRulesDialogOpen } = props
	const [isVisible, setIsVisible] = useState(false)

	const openOnEuCountry = useCallback(() => {
		fetchIpData
			.then((data) => {
				if (data?.country && !europeanCountryCodes.includes(data.country)) {
					setIsVisible(false)
				} else {
					setIsVisible(true)
				}
			})
			.catch(() => {
				setIsVisible(true)
			})
	}, [setIsVisible])

	/**
	 * Set a persistent cookie
	 */

	const onAccept = useCallback(() => {
		Cookies.set('cookie-consent-site-terms-conditions', 'true', {
			expires: 365,
			secure: true,
		})
		setIsVisible(false)
	}, [setIsVisible])

	useEffect(() => {
		if (Cookies.get('cookie-consent-site-terms-conditions') === undefined) {
			openOnEuCountry()
		}
	}, [openOnEuCountry])

	return (
		<Snackbar
			// eslint-disable-next-line react/prop-types
			className={classes.snackbarContent}
			open={isVisible}
			message={
				<Typography>
					We use cookies to ensure you get the best experience on our website.{' '}
				</Typography>
			}
			action={
				<Fragment>
					<Box mr={1}>
						<Button color='primary' onClick={handleCookieRulesDialogOpen}>
							More details
						</Button>
					</Box>
					<Button color='primary' onClick={onAccept}>
						Got it!
					</Button>
				</Fragment>
			}
		/>
	)
}

CookieConsent.propTypes = {
	handleCookieRulesDialogOpen: PropTypes.func.isRequired,
}

export default withStyles(styles, { withTheme: true })(CookieConsent)
