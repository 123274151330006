// @ts-nocheck
import React from 'react'
import CardOne from '../cards/CardOne'
import CardTwo from '../cards/CardTwo'
import { Box, Grid, Hidden, makeStyles } from '@material-ui/core'
import CenteredTabs from '../tabs/CenteredTabs'
import FullWidthTabs from '../tabs/FullWidthTabs'
import ParallaxCarousel from '../sliders/Slider.jsx'

const useStyles = makeStyles((theme) => ({
	gridContainer: {
		padding: theme.spacing(4),
	},
	aosCard: {
		'data-aos': 'fade-up',
		'data-aos-duration': 2000,
	},
}))
export default function CardSection() {
	const classes = useStyles()
	return (
		<div>
			<Box>
				<Grid container>
					<Grid item xs={12} md={6} className={classes.gridContainer}>
						<CardOne className={classes.aosCard} />
					</Grid>
					<Grid item xs={12} md={6} className={classes.gridContainer}>
						<CardTwo className={classes.aosCard} />
					</Grid>
				</Grid>
			</Box>
			<Box>
				<Hidden smDown>
					<CenteredTabs />
				</Hidden>
				<Hidden mdUp>
					<FullWidthTabs />
				</Hidden>
			</Box>
			<Box pb={8}>
				<ParallaxCarousel />
			</Box>
		</div>
	)
}
