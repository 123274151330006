// @ts-nocheck
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Typography, withStyles } from '@material-ui/core'

const styles = (theme) => ({
	root: {
		backgroundColor: '#2196f333',
		padding: theme.spacing(1),
		borderRadius: theme.shape.borderRadius,
		width: '95%',
		margin: 'auto',
	},
	text: {
		textAlign: 'center',
	},
})

function HighlighedInformation(props) {
	const { className, children, classes } = props
	return (
		<div className={classNames(classes.root, className ? className : null)}>
			<Typography
				variant='body2'
				className={classNames(classes.text, className ? className : null)}>
				{children}
			</Typography>
		</div>
	)
}

HighlighedInformation.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element,
		PropTypes.array,
	]).isRequired,
	className: PropTypes.string,
}

export default withStyles(styles, { withTheme: true })(HighlighedInformation)
