// @ts-nocheck
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	FormHelperText,
	Link,
	Snackbar,
	TextField,
	Tooltip,
	Typography,
	withStyles,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'
import React, { Fragment, useCallback, useRef, useState } from 'react'
import registerCaptcha from '../../utils/registerCaptcha'
import ButtonCircularProgress from '../components/animations/ButtonCircularProgress'
import HighlightedInformation from '../components/animations/HighlightedInformation'
import FormDialog from '../components/dialogs/FormDialog'
import { supabase } from './supabaseClient'

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />
}

const styles = (theme) => ({
	link: {
		transition: theme.transitions.create(['backgroundColor'], {
			duration: theme.transitions.duration.complex,
			easing: theme.transitions.easing.easeInOut,
		}),
	},
	buttonBox: {
		width: '50%',
		margin: 'auto',
	},
	button: {
		'backgroundColor': 'rgba(244, 119, 33, 0.9)',
		'color': '#FFF',
		'&:hover': {
			backgroundColor: 'rgba(244, 119, 33)',
		},
	},
	formControlLabel: {
		marginRight: 0,
	},
	text: {
		textAlign: 'center',
	},
})

function Register(props) {
	const { setStatus, theme, onClose, openTermsDialog, classes } = props
	const [loading, setLoading] = useState(false)
	const [hasTermsOfServiceError, setHasTermsOfServiceError] = useState(false)
	const [registered, setRegistered] = useState(false)
	const [hasUnknownError, setHasUnknownError] = useState(false)
	const [email, setEmail] = useState('')
	const registerTermsCheckbox = useRef()

	const handleRegister = async () => {
		try {
			const { err } = await supabase.auth.signIn({ email })
			if (err) throw err
			setRegistered(true)
		} catch (err) {
			if (err) {
				setHasUnknownError(true)
			}
		} finally {
			setLoading(false)
		}
	}
	const handleAuth = useCallback(() => {
		setStatus(null)
		setLoading(true)
		registerCaptcha()
		if (!registerTermsCheckbox.current.checked) {
			setTimeout(() => {
				setHasTermsOfServiceError(true)
				setLoading(false)
			}, 1500)
		} else handleRegister()
	}, [
		setLoading,
		setStatus,
		registerCaptcha,
		setHasTermsOfServiceError,
		registerTermsCheckbox,
		handleRegister,
	])

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		setRegistered(false)
		setHasUnknownError(false)
	}

	const longText = `Simply Provide an Email Address, No Password Required. Login Using the Link in the Confirmation Email!`

	return (
		<Fragment>
			<form id='register-form' validate='true' disabled={loading}>
				<FormDialog
					open
					onClose={onClose}
					headline='Register'
					content={
						<Fragment>
							<Tooltip
								title={longText}
								className={classes.text}
								interactive
								arrow
								placement='top'>
								<TextField
									autoComplete='email'
									required={true}
									id='reg1-email-input'
									type='email'
									name='email'
									label='Email'
									disabled={loading}
									onChange={(e) => setEmail(e.target.value)}
									value={email}
									placeholder='📧'
									fullWidth={true}
									margin='normal'
									variant='outlined'
									color='secondary'
								/>
							</Tooltip>
							<FormControlLabel
								className={classes.formControlLabel}
								control={
									<Checkbox
										color='secondary'
										inputRef={registerTermsCheckbox}
										onChange={() => {
											setHasTermsOfServiceError(false)
										}}
									/>
								}
								label={
									<Typography variant='body1'>
										I agree to the
										<Link
											color='secondary'
											onClick={loading ? null : openTermsDialog}
											tabIndex={0}>
											{' '}
											terms and conditions
										</Link>
										. *
									</Typography>
								}
							/>
							{hasTermsOfServiceError && (
								<FormHelperText
									error
									style={{
										display: 'block',
										marginTop: theme.spacing(-1),
									}}>
									To create an account, you must agree to our terms & conditions.
								</FormHelperText>
							)}
							<Box mt={2}>
								<HighlightedInformation>
									This site is protected by reCAPTCHA and the Google{' '}
									<Link
										color='secondary'
										href='https://policies.google.com/privacy'
										target='_blank'
										rel='noopener noreferrer nofollow'>
										Privacy Policy
									</Link>{' '}
									and{' '}
									<Link
										color='secondary'
										href='https://policies.google.com/terms'
										target='_blank'
										rel='noopener noreferrer nofollow'>
										Terms of Service
									</Link>{' '}
									apply.
								</HighlightedInformation>
							</Box>
						</Fragment>
					}
					actions={
						<Fragment>
							<Box className={classes.buttonBox}>
								<Button
									fullWidth
									type='submit'
									id='submit-register-form'
									variant='contained'
									className={classes.button}
									disabled={loading}
									size='large'
									onClick={(e) => {
										e.preventDefault()
										handleAuth(email)
									}}>
									Register
									{loading && <ButtonCircularProgress />}
								</Button>
							</Box>
						</Fragment>
					}
				/>
			</form>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				open={registered}
				autoHideDuration={6000}
				onClose={handleClose}>
				<Alert onClose={handleClose} severity='success'>
					We have created your account. Please check your email for the login link.
					Don't forget to check your spam box just in case it was flagged!
				</Alert>
			</Snackbar>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				open={hasUnknownError}
				autoHideDuration={6000}
				onClose={handleClose}>
				<Alert onClose={handleClose} severity='error'>
					Apologies, something went wrong! Please wait a moment and try again.
				</Alert>
			</Snackbar>
		</Fragment>
	)
}

Register.propTypes = {
	theme: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	openTermsDialog: PropTypes.func.isRequired,
	setStatus: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(Register)
