// @ts-nocheck
import { Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import { indigo } from '@material-ui/core/colors'
import {
	Avatar,
	Box,
	Card,
	CardHeader,
	CardActionArea,
	CardContent,
	CardMedia,
	Typography,
	Divider,
} from '@material-ui/core'
import icon from '../../../assets/zlw.svg'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		minWidth: 280,
		maxWidth: 420,
		flexDirection: 'column',
	},
	ul: {
		'list-style-type': 'none',
	},
	media: {
		height: 190,
	},
	avatar: {
		backgroundColor: indigo[100],
	},
	divider: {
		marginTop: '5px',
		marginBottom: '0px',
		height: '3px',
		backgroundColor: theme.palette.secondary.main,
	},
	details: {
		marginTop: '10px',
		marginBottom: '10px',
	},
	text: {
		gutterBottom: true,
	},
}))

export default function Zillow() {
	const classes = useStyles()
	const [zillow, setZillow] = useState(null)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)

	useEffect(() => {
		const zillow = `https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.zillow.com%2Fblog%2Ffeed&api_key=${process.env.REACT_APP_RSS_API_KEY}&count=1`

		const getZillow = async () => {
			try {
				const response = await axios.get(zillow)
				setZillow(response.data)
				setError(null)
			} catch (err) {
				setError(err.message)
				setZillow(null)
			} finally {
				setLoading(false)
			}
		}
		getZillow()
	}, [])

	return (
		<div>
			{error && <div>{`There is a problem fetching the post data - ${error}`}</div>}
			<ul className={classes.ul}>
				{zillow?.items.map(({ guid, author, title, link, description }) => (
					<li key={guid}>
						<Card className={classes.root}>
							<CardHeader
								avatar={
									loading ? (
										<Skeleton
											animation='wave'
											variant='circle'
											width={40}
											height={40}
										/>
									) : (
										<a
											href={zillow?.feed.url}
											target='_blank'
											rel='noopener noreferrer nofollow'>
											<Avatar
												className={classes.avatar}
												src={zillow?.feed.image}
												alt={zillow?.feed.description}
											/>
										</a>
									)
								}
								title={
									loading ? (
										<Skeleton
											animation='wave'
											height={10}
											width='80%'
											style={{ marginBottom: 6 }}
										/>
									) : (
										author
									)
								}
								subheader={
									loading ? (
										<Skeleton animation='wave' height={10} width='40%' />
									) : (
										'Zillow Porchlight'
									)
								}
							/>
							<CardActionArea href={link} target='_blank' rel='noopener noreferrer nofollow'>
								<CardMedia
									className={classes.media}
									image={icon}
									alt={description}
								/>
								<Divider className={classes.divider} />
								<CardContent className={classes.details}>
									{loading ? (
										<Fragment>
											<Skeleton
												animation='wave'
												height={10}
												style={{ marginBottom: 6 }}
											/>
											<Skeleton animation='wave' height={10} width='80%' />
										</Fragment>
									) : (
										<Fragment>
											<Box letterSpacing={1.5}>
												<Typography
													className={classes.text}
													variant='subtitle2'
													component='h2'>
													{title}
												</Typography>
											</Box>
										</Fragment>
									)}
								</CardContent>
							</CardActionArea>
						</Card>
					</li>
				))}
			</ul>
		</div>
	)
}
