// @ts-nocheck
import React, { lazy, Suspense, StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import App from './App'
import LoadingAnimation from './core/components/animations/LoadingAnimation'
import HomePage from './routes/root/Root'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

const queryClient = new QueryClient()
const About = lazy(() => import('./routes/about/About'))
const Solutions = lazy(() => import('./routes/solutions/Solutions'))
const Contact = lazy(() => import('./routes/contact/Contact'))
const Resources = lazy(() => import('./routes/resources/Resources'))
const Dashboard = lazy(() => import('./core/auth/AuthHandler'))
const FourOFour = lazy(() => import('./routes/error/Error'))

const rootElement = document.getElementById('root')
ReactDOM.render(
	<StrictMode>
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				<Routes>
					<Route path='/' element={<App />}>
						<Route index element={<HomePage />} />
						<Route
							path='about'
							element={
								<Suspense fallback={<LoadingAnimation />}>
									<About />
								</Suspense>
							}
						/>
						<Route
							path='solutions'
							element={
								<Suspense fallback={<LoadingAnimation />}>
									<Solutions />
								</Suspense>
							}
						/>
						<Route
							path='contact'
							element={
								<Suspense fallback={<LoadingAnimation />}>
									<Contact />
								</Suspense>
							}
						/>
						<Route
							path='resources'
							element={
								<Suspense fallback={<LoadingAnimation />}>
									<Resources />
								</Suspense>
							}
						/>
						<Route
							path='dashboard'
							element={
								<Suspense fallback={<LoadingAnimation />}>
									<Dashboard />
								</Suspense>
							}
						/>
						<Route
							path='*'
							element={
								<Suspense fallback={<LoadingAnimation />}>
									<FourOFour />
								</Suspense>
							}
						/>
					</Route>
				</Routes>
			</BrowserRouter>
		</QueryClientProvider>
	</StrictMode>,
	rootElement,
)

// enable service worker & WebVitals on production
if (process.env.NODE_ENV === 'production') {
	serviceWorkerRegistration.register()
} else {
	serviceWorkerRegistration.unregister()
	reportWebVitals(console.log)
}

// Learn more about service workers: https://cra.link/PWA
// Learn more about reporting WebVitals. Learn more: https://bit.ly/CRA-vitals
