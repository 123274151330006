// @ts-nocheck
import {
	AppBar,
	Box,
	Button,
	ButtonGroup,
	Hidden,
	IconButton,
	Toolbar,
	withStyles,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { Link, Outlet } from 'react-router-dom'
import logo from '../../../assets/ormica-company-logo.svg'
import NavigationDrawer from '../sections/NavigationDrawer'

const styles = (theme) => ({
	appBar: {
		boxShadow: theme.shadows[3],
		backgroundColor: '#fbfbfd',
		lineHeight: 1,
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	menuButtonText: {
		fontSize: theme.typography.body1.fontSize,
		fontWeight: 'bold',
		color: theme.palette.primary.dark,
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	brandText: {
		fontFamily: 'Calibri, sans-serif',
		fontWeight: 400,
	},
	noDecoration: {
		textDecoration: 'none !important',
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
})
function NavBar(props) {
	const {
		classes,
		handleMobileDrawerOpen,
		handleMobileDrawerClose,
		mobileDrawerOpen,
		selectedTab,
	} = props

	const menuItems = [
		{
			name: 'SOLUTIONS',
			link: '/solutions',
		},
		{
			name: 'RESOURCES',
			link: '/resources',
		},
		{
			name: 'ABOUT',
			link: '/about',
		},
	]

	return (
		<div>
			<nav className={classes.root}>
				<Box mt={2}>
					<AppBar elevation={3} position='static' className={classes.appBar}>
						<Toolbar className={classes.toolbar}>
							<div align='center'>
								<Link to='/'>
									<Box
										component={'img'}
										width={'120px'}
										height={'70px'}
										src={logo}
										alt='Ormica Company Logo'
									/>
								</Link>
							</div>
							<div style={{ width: '80%' }}>
								<Box display='flex' justifyContent='flex-end'>
									<Hidden mdUp>
										<IconButton
											className={classes.menuButton}
											onClick={handleMobileDrawerOpen}
											aria-label='Open Navigation'>
											<MenuIcon color='primary' />
										</IconButton>
									</Hidden>
									<Hidden smDown>
										<ButtonGroup
											variant='text'
											color='primary'
											aria-label='text primary button group'>
											{menuItems.map((element) =>
												element ? (
													<Link
														key={element.name}
														to={element.link}
														className={classes.noDecoration}
														onClick={handleMobileDrawerClose}>
														<Button
															color='primary'
															size='large'
															classes={{ text: classes.menuButtonText }}>
															{element.name}
														</Button>
													</Link>
												) : (
													<Button
														classes={{ text: classes.menuButtonText }}
														onClick={element.onClick}
														key={element.name}>
														{element.name}
													</Button>
												),
											)}
										</ButtonGroup>
									</Hidden>
								</Box>
							</div>
						</Toolbar>
					</AppBar>
				</Box>
				<NavigationDrawer
					menuItems={menuItems}
					anchor='right'
					open={mobileDrawerOpen}
					selectedItem={selectedTab}
					onClose={handleMobileDrawerClose}
				/>
			</nav>
			<Outlet />
		</div>
	)
}

NavBar.propTypes = {
	classes: PropTypes.object.isRequired,
	handleMobileDrawerOpen: PropTypes.func,
	handleMobileDrawerClose: PropTypes.func,
	mobileDrawerOpen: PropTypes.bool,
	selectedTab: PropTypes.string,
	openRegister: PropTypes.func,
	openLogin: PropTypes.func,
}

export default withStyles(styles, { withTheme: true })(memo(NavBar))
