// @ts-nocheck
import {
	AppBar,
	Box,
	Grid,
	Tab,
	Tabs,
	Typography,
	useTheme,
} from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SwipeableViews from 'react-swipeable-views'
import { TabPanel } from './TabPanel'

function a11yProps(index) {
	return {
		'id': `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	}
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100vw',
		backgroundColor: theme.palette.background.paper,
		borderRadius: 0,
	},
	paraMargin: {
		textAlign: 'center',
		[theme.breakpoints.up('xs')]: {
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
		},
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(4),
			marginRight: theme.spacing(4),
		},
		[theme.breakpoints.up('md')]: {
			marginLeft: theme.spacing(10),
			marginRight: theme.spacing(10),
		},
	},
}))

export default function FullWidthTabs() {
	const classes = useStyles()
	const theme = useTheme()
	const [value, setValue] = React.useState(0)

	const handleChange = (_event, newValue) => {
		setValue(newValue)
	}

	const handleChangeIndex = (index) => {
		setValue(index)
	}

	return (
		<section className={classes.root}>
			<AppBar position='static'>
				<Box mx={'auto'}>
					<Grid
						container
						direction='column'
						justifyContent='center'
						alignItems='center'>
						<Grid item xs={12}>
							<Box pt={3} pb={1} letterSpacing={4}>
								<Typography variant='h4' component='h3'>
									SERVICES WE PROVIDE
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Box>
				<Tabs
					value={value}
					onChange={handleChange}
					indicatorColor='secondary'
					variant='fullWidth'
					aria-label='full width swipeable tabs'>
					<Tab label='Advice' href='/' {...a11yProps(0)} />
					<Tab label='Financing' href='/' {...a11yProps(1)} />
					<Tab label='Results' href='/' {...a11yProps(2)} />
				</Tabs>
			</AppBar>
			<SwipeableViews
				axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={value}
				onChangeIndex={handleChangeIndex}>
				<TabPanel value={value} index={0} dir={theme.direction}>
					<Typography className={classes.paraMargin}>
						If you don&apos;t see a good match in our current inventory, just let us
						know. Ormica is always acquring new properties, so we&apos;re confident
						that we can find the perfect home for you.
					</Typography>
				</TabPanel>
				<TabPanel value={value} index={1} dir={theme.direction}>
					<Typography className={classes.paraMargin}>
						We buy homes in any type of financial situation. We work with the banks,
						are familiar with the foreclosure process, and often buy homes that are
						behind on payments.
					</Typography>
				</TabPanel>
				<TabPanel value={value} index={2} dir={theme.direction}>
					<Typography className={classes.paraMargin}>
						If you are in financial distress or just ready to move on but having a
						hard time selling, oftentimes we can make you a fair cash offer almost
						immediately and close very quickly.
					</Typography>
				</TabPanel>
			</SwipeableViews>
		</section>
	)
}
