// @ts-nocheck
import { makeStyles } from '@material-ui/core'
import React from 'react'
import CardSection from '../../core/components/sections/CardSection'
import HeadSection from '../../core/components/sections/HeadSection'
import smoothScrollTop from '../../utils/smoothScrollTop'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100vw',
		borderRadius: 0,
		boxShadow: theme.shadows[1],
	},
}))

const RootPage = () => {
	document.title =
		'Ormica – Premier Real Estate Developers – Serving Miami/S. Florida'
	document.cookie = 'SameSite=None Secure'
	smoothScrollTop()
	const classes = useStyles()
	return (
		<main id='root-route' className={classes.root}>
			<section>
				<HeadSection />
			</section>
			<section>
				<CardSection />
			</section>
		</main>
	)
}
export default RootPage
