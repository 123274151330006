// @ts-nocheck
import React from 'react'
import { SocialLink, SocialProvider } from '@mui-treasury/components/socialLink'
import { useRoundSocialLinkStyles } from '@mui-treasury/styles/socialLink/round'

export const SocialIcons = () => (
	<SocialProvider useStyles={useRoundSocialLinkStyles}>
		<SocialLink brand={'FacebookCircle'} href={'https://facebook.com/Ormica/'} />
		<SocialLink
			brand={'LinkedIn'}
			href={'https://www.linkedin.com/company/ormica/'}
		/>
		<SocialLink brand={'GithubCircle'} href={'https://github.com/Ormica-LLC/'} />
	</SocialProvider>
)
