// @ts-nocheck
import {
	AppBar,
	Box,
	Fab,
	Grid,
	InputBase,
	Slide,
	Toolbar,
	useScrollTrigger,
	Zoom,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import React from 'react'
import { alpha, makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	root: {
		position: ['-webkit-sticky', 'sticky'],
		top: 0,
	},
	grow: {
		boxShadow: theme.shadows[4],
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		color: 'rgba(243, 248, 252)',
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			'width': '60vw',
			'&:focus': {
				width: '80vw',
			},
		},
	},
	toolbar: {
		backgroundColor: theme.palette.primary.main,
	},
	scroll: {
		transitionTimingFunction: 'ease-out',
	},
	goButton: {
		color: theme.palette.common.white,
	},
	link: {
		textDecoration: 'none',
	},
	scrollButtonBox: {
		position: 'fixed',
		bottom: theme.spacing(4),
		right: theme.spacing(4),
	},
	search: {
		'position': 'relative',
		'borderRadius': theme.shape.borderRadius,
		'backgroundColor': alpha(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.white, 0.25),
		},
		'boxShadow': theme.shadows[2],
		'marginLeft': 0,
		'width': '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(1),
			width: 'auto',
		},
	},
	searchIcon: {
		pointerEvents: 'none',
		color: theme.palette.secondary.main,
		marginLeft: theme.spacing(3),
	},
}))

function ScrollTop(props) {
	const { children } = props
	const classes = useStyles()
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 500,
	})

	const handleClick = (event) => {
		const anchor = (event.target.ownerDocument || document).querySelector(
			'#scroll-anchor',
		)

		if (anchor) {
			anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
		}
	}

	return (
		<Zoom in={trigger} className={classes.scroll}>
			<Box
				onClick={handleClick}
				role='presentation'
				sx={{ position: 'fixed', bottom: 100, right: 30, zIndex: 9999 }}>
				{children}
			</Box>
		</Zoom>
	)
}

ScrollTop.propTypes = {
	children: PropTypes.element.isRequired,
}

function ScrollBar(props) {
	const { children } = props
	const classes = useStyles()
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 300,
	})

	return (
		<Slide in={trigger} direction='down'>
			<AppBar
				className={classes.grow}
				sx={{ position: 'fixed', top: 0, right: 0, left: 0 }}>
				{children}
			</AppBar>
		</Slide>
	)
}

ScrollBar.propTypes = {
	children: PropTypes.element.isRequired,
}

export default function SearchBar(props) {
	const classes = useStyles()
	return (
		<div id='scroll-anchor' className={classes.root}>
			<ScrollBar {...props}>
				<Toolbar variant='dense' className={classes.toolbar}>
					<Grid
						container
						direction='row-reverse'
						justifyContent='space-between'
						alignItems='flex-end'>
						<div className={classes.search}>
							<Grid
								container
								direction='row'
								justifyContent='space-between'
								alignItems='center'>
								<SearchIcon ml={1} className={classes.searchIcon} />
								<InputBase
									placeholder='Zip, Address, City, State...'
									classes={{
										root: classes.inputRoot,
										input: classes.inputInput,
									}}
									inputProps={{ 'aria-label': 'search' }}
								/>
							</Grid>
						</div>
						<div className={classes.grow} />
					</Grid>
				</Toolbar>
			</ScrollBar>
			<ScrollTop {...props}>
				<Fab color='secondary' size='medium' aria-label='scroll back to top'>
					<KeyboardArrowUpIcon />
				</Fab>
			</ScrollTop>
		</div>
	)
}
