// @ts-nocheck
import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box, withStyles, withWidth, isWidthUp } from '@material-ui/core'
import ButtonGrid from '../buttons/ButtonGrid'

const styles = (theme) => ({
	extraLargeButtonLabel: {
		fontSize: theme.typography.body1.fontSize,
		[theme.breakpoints.up('sm')]: {
			fontSize: theme.typography.h6.fontSize,
		},
	},
	extraLargeButton: {
		paddingTop: theme.spacing(1.5),
		paddingBottom: theme.spacing(1.5),
		[theme.breakpoints.up('xs')]: {
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
		},
		[theme.breakpoints.up('lg')]: {
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(2),
		},
	},
	background: {
		backgroundColor: theme.palette.background.default,
		backgroundImage:
			'url("https://imagedelivery.net/V1nQb2Wy2XhC693mJyMETg/193e4d25-218d-4bf9-6c77-795d629e1500/1800x1012")',
		backgroundRepeat: 'no-repeat',
		backgroundAttachment: 'fixed',
		backgroundPosition: 'center center',
		backgroundSize: 'cover',
	},
	filter: {
		background: 'rgba(54, 54, 54, 0.7)',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundAttachment: 'fixed',
		width: '100%',
	},
	box: {
		paddingTop: theme.spacing(10),
	},
	container: {
		marginBottom: theme.spacing(2),
		width: '100vw',
		height: '80vh',
	},
	containerFix: {
		[theme.breakpoints.up('md')]: {
			maxWidth: 'none !important',
		},
	},
	lowercase: {
		textTransform: 'capitalize',
		color: '#ECECF0',
	},
	typo: {
		color: '#ECECF0',
	},
})

function HeadSection(props) {
	const { classes, width } = props
	return (
		<div className={classes.background}>
			<div className={classes.filter}>
				<div className={classes.container}>
					<Box
						display='flex'
						flexDirection='column'
						justifyContent='space-between'
						className={classes.box}>
						<Box p={2} mt={3} letterSpacing={6}>
							<Typography
								className={classes.lowercase}
								align='center'
								component='h1'
								variant={isWidthUp('lg', width) ? 'h4' : 'h3'}>
								The Real Estate Professionals
							</Typography>
						</Box>
						<Box mt={2} mb={1} p={1} letterSpacing={6}>
							<Typography
								className={classes.typo}
								align='center'
								letterSpacing='0.4em'
								component='h2'
								variant={isWidthUp('lg', width) ? 'h3' : 'h2'}>
								MAKING THE PROCESS SIMPLE
							</Typography>
						</Box>
						<Box mt={4} mb={28}>
							<ButtonGrid />
						</Box>
					</Box>
				</div>
			</div>
		</div>
	)
}

HeadSection.propTypes = {
	classes: PropTypes.object,
	width: PropTypes.string,
	theme: PropTypes.object,
}

export default withWidth()(withStyles(styles, { withTheme: true })(HeadSection))
