// @ts-nocheck
import {
	Box,
	Grid,
	makeStyles,
	Paper,
	Tab,
	Tabs,
	Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import DividerCommon from '../sections/DividerCommon'

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`nav-tabpanel-${index}`}
			aria-labelledby={`nav-tab-${index}`}
			{...other}>
			{value === index && (
				<Box py={3} px={12}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
}

function a11yProps(index) {
	return {
		'id': `nav-tab-${index}`,
		'aria-controls': `nav-tabpanel-${index}`,
	}
}

function LinkTab(props) {
	return (
		<Tab
			component='a'
			onClick={(event) => {
				event.preventDefault()
			}}
			{...props}
		/>
	)
}

const useStyles = makeStyles({
	root: {
		width: '100vw',
		borderRadius: 0,
		display: 'grid',
		placeItems: 'center',
		textAlign: 'center',
		paddingLeft: '10%',
		paddingRight: '10%',
	},
	linkTab: {
		paddingLeft: '5%',
		paddingRight: '5%',
	},
})

export default function CenteredTabs() {
	const classes = useStyles()
	const [value, setValue] = React.useState(0)

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	return (
		<div>
			<Paper className={classes.root}>
				<Box mx={'auto'}>
					<Grid
						container
						direction='column'
						justifyContent='center'
						alignItems='center'>
						<Grid item xs={12}>
							<Box pt={3} pb={1} letterSpacing={4}>
								<Typography variant='h4' component='h3'>
									SERVICES WE PROVIDE
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Box>
				<DividerCommon />
				<Tabs value={value} onChange={handleChange} centered aria-label='nav tab'>
					<LinkTab label='Advice' {...a11yProps(0)} className={classes.linkTab} />
					<LinkTab label='Financing' {...a11yProps(1)} className={classes.linkTab} />
					<LinkTab label='Results' {...a11yProps(2)} className={classes.linkTab} />
				</Tabs>
				<TabPanel className={classes.tabText} value={value} index={0}>
					<Typography className={classes.tabText}>
						If you don&apos;t see a good match in our current inventory, just let us
						know. Ormica is always acquring new properties, so we&apos;re confident
						that we can find the perfect home for you.
					</Typography>
				</TabPanel>
				<TabPanel className={classes.tabText} value={value} index={1}>
					<Typography className={classes.tabText}>
						We buy homes in any type of financial situation. We work with the banks,
						are familiar with the foreclosure process, and often buy homes that are
						behind on payments.
					</Typography>
				</TabPanel>
				<TabPanel className={classes.tabText} value={value} index={2}>
					<Typography>
						If you are in financial distress or just ready to move on but having a
						hard time selling, oftentimes we can make you a fair cash offer almost
						immediately and close very quickly.
					</Typography>
				</TabPanel>
			</Paper>
		</div>
	)
}
