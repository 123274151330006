// @ts-nocheck
import {
	Box,
	Button,
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useCoverCardMediaStyles } from '@mui-treasury/styles/cardMedia/cover'
import { useLightTopShadowStyles } from '@mui-treasury/styles/shadow/lightTop'
import React from 'react'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 600,
		maxHeight: 338,
		borderRadius: 10,
		position: 'relative',
		margin: theme.spacing(2),
		[theme.breakpoints.down('md')]: {
			margin: theme.spacing(1),
		},
	},
	content: {
		padding: theme.spacing(0),
	},
	edgesLower: {
		paddingBottom: theme.spacing(4),
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	button: {
		'marginRight': theme.spacing(2),
		'background': 'rgba(243, 248, 252, 0.7)',
		'&:hover': {
			background: 'rgba(243, 248, 252)',
		},
		'color': theme.palette.primary.main,
		'fontWeight': 700,
	},
}))

export const CardOne = React.memo(function NewsCard2() {
	const styles = useStyles()
	const mediaStyles = useCoverCardMediaStyles()
	const shadowStyles = useLightTopShadowStyles()
	return (
		<Card className={`${styles.root} ${shadowStyles.root}`}>
			<CardMedia
				classes={mediaStyles}
				image={
					'https://imagedelivery.net/V1nQb2Wy2XhC693mJyMETg/044f6aaa-1612-46b0-a881-d575aa4c4b00/600x338'
				}
			/>
			<CardActionArea>
				<CardContent className={styles.content}>
					<Link to='/solutions'>
						<Box
							display={'flex'}
							flexDirection={'column'}
							alignItems={'end'}
							justifyContent={'flex-end'}
							minHeight={338}
							color={'common.white'}
							textAlign={'center'}
							className={styles.edgesLower}>
							<Link to='/solutions'>
								<Button size='large' variant='contained' className={styles.button}>
									<Typography variant='button'>GET AN OFFER</Typography>
								</Button>
							</Link>
						</Box>
					</Link>
				</CardContent>
			</CardActionArea>
		</Card>
	)
})

export default CardOne
