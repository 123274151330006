// @ts-nocheck
import { makeStyles, Snackbar, Typography } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import {
	EmailSubscribe,
	EmailTextInput,
	SubmitButton,
} from '@mui-treasury/components/EmailSubscribe'
import { ColumnToRow, Item } from '@mui-treasury/components/flex'
import { useReadyEmailSubscribeStyles } from '@mui-treasury/styles/emailSubscribe/ready'
import React from 'react'
import subscribeCaptcha from '../../../utils/subscribeCaptcha'
import ButtonCircularProgress from '../animations/ButtonCircularProgress'

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />
}

const useStyles = makeStyles({
	newsletterText: {
		color: '#fff',
		textTransform: 'uppercase',
		letterSpacing: '0.02rem',
		fontFamily: 'calibri, sans-serif',
		fontWeight: 300,
		fontStyle: 'normal',
	},
	form: {
		margin: 0,
		minWidth: 319,
		fontSize: '0.875rem',
	},
})

const SubscribeInput = () => {
	const classes = useStyles()
	const [sending, setSending] = React.useState(false)
	const [delivered, setDelivered] = React.useState(false)
	const [failed, setFailed] = React.useState(false)

	const subscribeEmail = async (email) => {
		setSending(true)
		subscribeCaptcha()
		await fetch('/api/mailserver', {
			method: 'POST',
			body: `email=${email}`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		})
			.then((res) => {
				if (res.status == 200) {
					setDelivered(true)
				} else {
					setFailed(true)
				}
			})
			.catch((err) => {
				if (err) {
					setFailed(true)
				}
			})
			.finally(() => setSending(false))
	}

	const handleClick = (token) => {
		console.log(token)
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		setDelivered(false)
		setFailed(false)
	}

	return (
		<ColumnToRow
			at={'sm'}
			cssPosition={'relative'}
			columnStyle={{ alignItems: 'center', textAlign: 'center' }}
			rowStyle={{ justifyContent: 'center' }}
			gap={{ xs: 2, sm: 3, md: 4 }}
			wrap={'true'}>
			<Item>
				<Typography className={classes.newsletterText}>
					Subscribe to our quarterly newsletter
				</Typography>
			</Item>
			<Item>
				<EmailSubscribe
					className={classes.form}
					id='newsletter-subscribe-form'
					onSubmit={subscribeEmail}
					useStyles={useReadyEmailSubscribeStyles}
					inputClearedAfterSubmit>
					<EmailTextInput placeholder='Enter your email' />
					<input type='hidden' id='newsletter-token' name='newsletter-token' />
					<SubmitButton
						disabled={sending}
						id='submit-newsletter-subscribe-form'
						style={{
							background: '#F47721',
							textTransform: 'uppercase',
							fontStyle: 'oblique',
						}}>
						{sending ? <ButtonCircularProgress color='secondary' /> : 'Subscribe'}
					</SubmitButton>
					<Snackbar
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						open={delivered}
						autoHideDuration={6000}
						onClose={handleClose}>
						<Alert onClose={handleClose} severity='success'>
							Your message was sent successfully!
						</Alert>
					</Snackbar>
					<Snackbar
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						open={failed}
						autoHideDuration={6000}
						onClose={handleClose}>
						<Alert onClose={handleClose} severity='error'>
							Something went wrong! Please try again or call us instead.
						</Alert>
					</Snackbar>
				</EmailSubscribe>
			</Item>
		</ColumnToRow>
	)
}

export default SubscribeInput
