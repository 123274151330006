// @ts-nocheck
import React, { memo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import TopBar from './components/headers/TopBar'
import SearchBar from './components/headers/SearchBar'
import NavBar from './components/headers/NavBar'
import Footer from './components/footers/Footer'
import CookieRulesDialog from './components/dialogs/CookieRulesDialog'
import CookieConsent from './components/cookies/CookieConsent'
import DialogSelector from './components/dialogs/DialogSelector'

const styles = () => ({
	wrapper: {
		overflowX: 'hidden',
	},
})

function PersistGlobals(props) {
	const { classes } = props
	const [selectedTab, setSelectedTab] = useState(null)
	const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false)
	const [dialogOpen, setDialogOpen] = useState(null)
	const [isCookieRulesDialogOpen, setIsCookieRulesDialogOpen] = useState(false)
	const openLogin = useCallback(() => {
		setDialogOpen('login')
		setIsMobileDrawerOpen(false)
	}, [setDialogOpen, setIsMobileDrawerOpen])

	const closeDialog = useCallback(() => {
		setDialogOpen(null)
		setIsMobileDrawerOpen(false)
	}, [setDialogOpen, setIsMobileDrawerOpen])

	const openRegister = useCallback(() => {
		setDialogOpen('register')
		setIsMobileDrawerOpen(false)
	}, [setDialogOpen, setIsMobileDrawerOpen])

	const openTermsDialog = useCallback(() => {
		setDialogOpen('termsOfService')
	}, [setDialogOpen])

	const handleMobileDrawerOpen = useCallback(() => {
		setIsMobileDrawerOpen(true)
	}, [setIsMobileDrawerOpen])

	const handleMobileDrawerClose = useCallback(() => {
		setIsMobileDrawerOpen(false)
	}, [setIsMobileDrawerOpen])

	const handleCookieRulesDialogOpen = useCallback(() => {
		setIsCookieRulesDialogOpen(true)
	}, [setIsCookieRulesDialogOpen])

	const handleCookieRulesDialogClose = useCallback(() => {
		setIsCookieRulesDialogOpen(false)
	}, [setIsCookieRulesDialogOpen])

	return (
		<div className={classes.wrapper}>
			{!isCookieRulesDialogOpen && (
				<CookieConsent handleCookieRulesDialogOpen={handleCookieRulesDialogOpen} />
			)}
			<DialogSelector
				openLogin={openLogin}
				dialogOpen={dialogOpen}
				onClose={closeDialog}
				openTermsDialog={openTermsDialog}
				openRegister={openRegister}
			/>
			<CookieRulesDialog
				open={isCookieRulesDialogOpen}
				onClose={handleCookieRulesDialogClose}
			/>
			<TopBar openLogin={openLogin} openRegister={openRegister} />
			<SearchBar />
			<NavBar
				mobileDrawerOpen={isMobileDrawerOpen}
				handleMobileDrawerOpen={handleMobileDrawerOpen}
				handleMobileDrawerClose={handleMobileDrawerClose}
				selectedTab={selectedTab}
				selectTab={setSelectedTab}
			/>
			<Footer openLogin={openLogin} openRegister={openRegister} />
		</div>
	)
}
PersistGlobals.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(memo(PersistGlobals))
