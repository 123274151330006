import React, { useState, useCallback, Fragment } from 'react'
import PropTypes from 'prop-types'
import Register from '../../auth/Register'
import TermsOfServiceDialog from './TermsOfServiceDialog'
import Login from '../../auth/Login'
import ModalBackdrop from '../cards/ModalBackdrop'

function DialogSelector(props) {
	const { dialogOpen, openTermsDialog, openRegister, openLogin, onClose } = props
	const [loginStatus, setLoginStatus] = useState(null)
	const [registerStatus, setRegisterStatus] = useState(null)

	const _onClose = useCallback(() => {
		setLoginStatus(null)
		setRegisterStatus(null)
		onClose()
	}, [onClose, setLoginStatus, setRegisterStatus])

	const printDialog = useCallback(() => {
		switch (dialogOpen) {
			case 'register':
				return (
					<Register
						onClose={_onClose}
						openTermsDialog={openTermsDialog}
						status={registerStatus}
						setStatus={setRegisterStatus}
					/>
				)
			case 'termsOfService':
				return <TermsOfServiceDialog onClose={openRegister} />
			case 'login':
				return (
					<Login
						onClose={_onClose}
						status={loginStatus}
						setStatus={setLoginStatus}
					/>
				)
			default:
		}
	}, [
		dialogOpen,
		openLogin,
		openRegister,
		openTermsDialog,
		_onClose,
		loginStatus,
		registerStatus,
		setLoginStatus,
		setRegisterStatus,
	])

	return (
		<Fragment>
			{dialogOpen && <ModalBackdrop open />}
			{printDialog()}
		</Fragment>
	)
}

DialogSelector.propTypes = {
	dialogOpen: PropTypes.string,
	openLogin: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	openTermsDialog: PropTypes.func.isRequired,
	openRegister: PropTypes.func.isRequired,
}

export default DialogSelector
