// @ts-nocheck
import {
	AppBar,
	Box,
	Button,
	makeStyles,
	Slide,
	Toolbar,
	useScrollTrigger,
	withStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { memo } from 'react'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '30px',
	},
	grow: {
		lineHeight: 1,
		flexGrow: 1,
		boxShadow: theme.shadows[4],
	},
	toolbar: {
		backgroundColor: theme.palette.primary.main,
	},
	scroll: {
		transitionTimingFunction: 'ease-out',
	},
	button: {
		color: '#ECECF0',
		marginLeft: theme.spacing(2),
		[theme.breakpoints.down('md')]: {
			marginLeft: theme.spacing(1),
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: 0,
		},
		marginRight: theme.spacing(2),
		[theme.breakpoints.down('md')]: {
			marginRight: theme.spacing(1),
		},
		[theme.breakpoints.down('sm')]: {
			marginRight: 0,
		},
	},
	menuButtonText: {
		fontSize: theme.typography.body1.fontSize,
		fontWeight: 'bold',
		color: theme.palette.primary.light,
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	noDecoration: {
		textDecoration: 'none !important',
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
}))

function HideOnScroll(props) {
	const { children } = props
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 295,
	})

	return (
		<Slide appear={false} direction='down' in={!trigger}>
			{children}
		</Slide>
	)
}

HideOnScroll.propTypes = {
	children: PropTypes.element.isRequired,
}
function TopBar(props) {
	const classes = useStyles()
	const { openRegister, openLogin } = props

	const menuItems = [
		{
			name: 'REGISTER',
			onClick: openRegister,
		},
		{
			name: 'LOGIN',
			onClick: openLogin,
		},
	]

	return (
		<div className={classes.root}>
			<HideOnScroll {...props}>
				<AppBar id='TopBar' className={classes.grow}>
					<Toolbar variant='dense' className={classes.toolbar}>
						<Box display='flex' justifyContent='flex-end' style={{ width: '100%' }}>
							{menuItems.map((element) => (
								<Button
									size='large'
									className={classes.button}
									onClick={element.onClick}
									key={element.name}>
									{element.name}
								</Button>
							))}
						</Box>
					</Toolbar>
				</AppBar>
			</HideOnScroll>
		</div>
	)
}

TopBar.propTypes = {
	openRegister: PropTypes.func.isRequired,
	openLogin: PropTypes.func.isRequired,
}
export default withStyles(makeStyles, { withTheme: true })(memo(TopBar))
