// @ts-nocheck
import React from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Grid,
	Link,
	Typography,
	withStyles,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {
	CategoryItem,
	CategoryProvider,
} from '@mui-treasury/components/menu/category'
import { useMagCategoryMenuStyles } from '@mui-treasury/styles/categoryMenu/mag'

const styles = (theme) => ({
	dialogTitle: {
		textTransform: 'uppercase',
	},
	dialogSubheading: {
		textDecoration: 'none',
	},
	dialogActions: {
		justifyContent: 'flex-start',
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	dialog: {
		zIndex: 1400,
	},
	backIcon: {
		marginRight: theme.spacing(1),
	},
	legalLink: {
		'textTransform': 'uppercase',
		'textDecoration': 'none',
		'color': 'rgba(243, 248, 252, 0.7)',
		'&:hover': {
			color: 'rgba(243, 248, 252)',
		},
	},
})

function CookieRulesDialog(props) {
	const { classes, onClose, open, theme } = props
	return (
		<Dialog open={open} scroll='paper' onClose={onClose} className={classes.dialog}>
			<Grid container direction='row' alignItems='center'>
				<Grid item xs={9}>
					<DialogTitle>
						<Typography variant='h5' className={classes.dialogTitle}>
							Our Cookie Policy
						</Typography>
					</DialogTitle>
				</Grid>
				<Grid item xs={3}>
					<Typography variant='subtitle1' className={classes.dateHeading}>
						Last updated:
						<br />
						October 15, 2021
					</Typography>
				</Grid>
			</Grid>
			<DialogContent>
				<Typography variant='h6' className={classes.dialogSubheading}>
					What Are Cookies?
				</Typography>
				<Typography paragraph>
					As is common practice with almost all professional websites this site uses
					cookies, which are tiny files that are downloaded to your computer, to
					improve your experience. This page describes what information they gather,
					how we use it and why we sometimes need to store these cookies.
				</Typography>
				<Typography paragraph>
					We will also share how you can prevent these cookies from being stored,
					however, this may downgrade or &apos;break&apos; certain elements of the
					sites functionality. For more general information on cookies see the
					Wikipedia article on HTTP Cookies.
				</Typography>
				<Typography variant='h6' className={classes.dialogSubheading}>
					How We Use Cookies
				</Typography>
				<Typography paragraph>
					In most cases there are no industry standard options for disabling cookies
					without completely disabling the functionality and features they add to
					this site. It is recommended that you leave on all cookies if you are not
					sure whether you need them or not in case they are used to provide a
					service that you use.
				</Typography>
				<Typography paragraph>
					Cookies do not typically contain any information that personally identifies
					a user, but personal information that we store about you may be linked to
					the information stored in and obtained from cookies. However, we do not
					store sensitive personal information, such as mailing addresses, account
					passwords, etc. in the cookies we use. We do use cookies for a variety of
					useful reasons detailed below.
				</Typography>
				<Typography variant='h6' className={classes.dialogSubheading}>
					Which Cookies We Use
				</Typography>
				<Typography variant='subtitle2'>Account Cookies:</Typography>
				<Typography paragraph>
					If you create an account with us then we will use cookies for the
					management of the signup process and general administration. These cookies
					will usually be deleted when you log out however in some cases they may
					remain afterwards to remember your site preferences when logged out. We use
					cookies to remember that you accepted this message.
				</Typography>
				<Typography variant='subtitle2'>Login Cookies:</Typography>
				<Typography paragraph>
					We use cookies when you are logged in so that we can remember this fact.
					This prevents you from having to log in every single time you visit a new
					page. These cookies are typically removed or cleared when you log out to
					ensure that you can only access restricted features and areas when logged
					in.
				</Typography>
				<Typography variant='subtitle2'>Preference Cookies:</Typography>
				<Typography paragraph>
					In order to provide you with a great experience on this site we provide the
					functionality to set your preferences for how this site runs when you use
					it. In order to remember your preferences we need to set cookies so that
					this information can be called whenever you interact with a page is
					affected by your preferences.
				</Typography>
				<Typography variant='subtitle2'>Third-Party Cookies:</Typography>
				<Typography paragraph>
					In some cases we also use cookies provided by trusted third parties. Third
					party analytics are used to track and measure usage of this site so that we
					can continue to produce engaging content. These cookies may track things
					such as time spent on the site or pages visited which helps us to learn how
					we can improve the site for you.
				</Typography>
				<Typography variant='h6' className={classes.dialogSubheading}>
					Disabling Cookies
				</Typography>
				<Typography paragraph>
					You can prevent the setting of cookies by adjusting the settings on your
					browser (see your browser Help for how to do this). Be aware that disabling
					cookies will affect the functionality of this and many other websites that
					you visit. Disabling cookies will usually result in also disabling certain
					functionality and features of the this site. Therefore it is recommended
					that you do not disable cookies.
				</Typography>
				<Typography variant='h6' className={classes.dialogSubheading}>
					Policy Summary
				</Typography>
				<Typography paragraph>
					Hopefully this has clarified things for you and as was previously mentioned
					if there is something that you aren&apos;t sure whether you need or not
					it&apos;s usually safer to leave cookies enabled in case it does interact
					with one of the features you use on our site.
				</Typography>
				<Typography paragraph>
					Please note, while cookies do not typically contain any information that
					personally identifies a user, personal information that we store about you
					may still be linked to the information stored in and obtained from cookies.
				</Typography>
				<Typography variant='h6' className={classes.dialogSubheading}>
					Additonal Information
				</Typography>
				<CategoryProvider styles={useMagCategoryMenuStyles}>
					<CategoryItem>
						To learn more about cookies, please reference this informative article.
					</CategoryItem>
					<Link
						color='secondary'
						href='https://www.freeprivacypolicy.com/blog/cookies/'
						target='_blank'
						rel='noopener noreferrer nofollow'>
						<CategoryItem>Cookies: What Do They Do?</CategoryItem>
					</Link>
				</CategoryProvider>
				<CategoryProvider styles={useMagCategoryMenuStyles}>
					<CategoryItem>
						If you have any questions about this Cookie Policy, you can contact us by
						email at
					</CategoryItem>
					<a
						href='mailto:support@ormica.com'
						target='_blank'
						rel='noopener noreferrer nofollow'>
						<CategoryItem>support@ormica.com</CategoryItem>
					</a>
				</CategoryProvider>
			</DialogContent>
			<DialogActions className={classes.dialogActions}>
				<Button onClick={onClose} variant='contained' backgroundColor={'#343a40'}>
					<ArrowBackIcon className={classes.backIcon} />
					Back
				</Button>
			</DialogActions>
		</Dialog>
	)
}

CookieRulesDialog.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	theme: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(CookieRulesDialog)
