// @ts-nocheck
import {
	createTheme,
	CssBaseline,
	MuiThemeProvider,
	responsiveFontSizes,
	useMediaQuery,
} from '@material-ui/core'
import React from 'react'
import './App.css'
import PersistGlobals from './core/PersistGlobals'

// border

const borderWidth = 1
const borderColor = '#F47721'

// breakpoints

const xl = 1920
const lg = 1280
const md = 960
const sm = 600
const xs = 0

// spacing

const spacing = 8

const themeLight = {
	type: 'light',
	primary: {
		main: '#005f9c',
		contrastText: 'fff',
	},
	secondary: {
		main: '#f47721',
		contrastText: '#000',
	},
	divider: 'rgba(0,12,24,0.12)',
	background: {
		default: '#fafafa',
		paper: '#fff',
	},
	text: {
		primary: 'rgba(0,12,24)',
		secondary: 'rgba(0,12,24,0.7)',
		disabled: 'rgba(0,12,24,0.45)',
		hint: 'rgba(0,12,24,0.5)',
	},
	action: {
		active: 'rgba(0,12,24)',
		hover: 'rgba(0,12,24,0.08)',
		selected: 'rgba(0,12,24,0.16)',
		disabled: 'rgba(0,12,24,0.3)',
		disabledBackground: 'rgba(0,0,12,24,0.12)',
		focus: 'rgba(0,12,24,0.12)',
		icon: 'rgba(0,12,24,0.26)',
		hint: 'rgba(0,12,24,0.26)',
	},
	tonalOffset: 0.2,
	spacing: 8,
	shape: {
		borderRadius: 5,
	},
	props: {
		MuiTooltip: {
			arrow: true,
		},
		MuiList: {
			dense: true,
		},
		MuiMenuItem: {
			dense: true,
		},
		MuiTable: {
			size: 'small',
		},
		MuiFormControl: {
			margin: 'dense',
			size: 'small',
		},
		MuiFormHelperText: {
			margin: 'dense',
		},
		MuiInputBase: {
			margin: 'dense',
		},
		MuiInputLabel: {
			margin: 'dense',
		},
		MuiTextField: {
			margin: 'dense',
			size: 'small',
		},
	},
	overrides: {
		MuiButton: {
			root: {
				'backgroundColor': 'rgba(244, 119, 33, 0.7)',
				'color': '#FFF',
				'&:hover': {
					backgroundColor: 'rgba(244, 119, 33, 0.95)',
				},
			},
		},
		MuiLink: {
			color: 'secondary',
		},
	},
}

const themeDark = {
	type: 'dark',
	primary: {
		main: '#005f9c',
		contrastText: 'fff',
	},
	secondary: {
		main: '#f47721',
		contrastText: '#000',
	},
	divider: 'rgba(243,248,252,0.12)',
	background: {
		default: '#1a2030',
		paper: '#292d3e',
	},
	text: {
		primary: '#f3f8fc',
		secondary: 'rgba(243,248,252,0.7)',
		disabled: 'rgba(243,248,252,0.45)',
		hint: 'rgba(243,248,252,0.5)',
	},
	action: {
		active: 'rgba(243,248,252,0.7)',
		hover: 'rgba(243,248,252)',
		selected: 'rgba(243,248,252,0.8)',
		disabled: 'rgba(243,248,252,0.3)',
		disabledBackground: 'rgba(243,248,252,0.12)',
		focus: 'rgba(243,248,252)',
		icon: 'rgba(243,248,252,0.6)',
		hint: 'rgba(243,248,252,0.6)',
	},
	tonalOffset: 0.2,
	spacing: 8,
	shape: {
		borderRadius: 5,
	},
	props: {
		MuiTooltip: {
			arrow: true,
		},
		MuiList: {
			dense: true,
		},
		MuiMenuItem: {
			dense: true,
		},
		MuiTable: {
			size: 'small',
		},
		MuiFormControl: {
			margin: 'dense',
			size: 'small',
		},
		MuiFormHelperText: {
			margin: 'dense',
		},
		MuiInputBase: {
			margin: 'dense',
		},
		MuiInputLabel: {
			margin: 'dense',
		},
		MuiTextField: {
			margin: 'dense',
			size: 'small',
		},
	},
	overrides: {
		MuiButton: {
			root: {
				'backgroundColor': 'rgba(244, 119, 33, 0.7)',
				'color': '#FFF',
				'&:hover': {
					backgroundColor: 'rgba(244, 119, 33, 0.95)',
				},
			},
		},
		MuiLink: {
			color: 'secondary',
		},
	},
}
export default function App() {
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

	let theme = React.useMemo(
		() =>
			createTheme({
				// @ts-ignore
				palette: prefersDarkMode ? themeDark : themeLight,
				breakpoints: {
					// Define custom breakpoint values.
					// These will apply to Material-UI components that use responsive
					// breakpoints, such as `Grid` and `Hidden`. You can also use the
					// theme breakpoint functions `up`, `down`, and `between` to create
					// media queries for these breakpoints

					values: {
						xl,
						lg,
						md,
						sm,
						xs,
					},
				},
				border: {
					borderColor,
					borderWidth,
				},
				typography: {
					useNextVariants: true,
					// @ts-ignore
					fontFamily: '"calibri", "ff-nuvo-web-pro", "sans-serif"',
					fontSize: 16,
					h1: {
						// @ts-ignore
						fontFamily: '"ff-nuvo-web-pro", "sans-serif"',
						fontWeight: 700,
						textTransform: 'uppercase',
					},
					h2: {
						// @ts-ignore
						fontFamily: '"ff-nuvo-web-pro", "sans-serif"',
						fontWeight: 700,
						textTransform: 'uppercase',
					},
					h3: {
						// @ts-ignore
						fontFamily: '"ff-nuvo-web-pro", "sans-serif"',
						fontWeight: 700,
						textTransform: 'uppercase',
					},
					h4: {
						// @ts-ignore
						fontFamily: '"ff-nuvo-web-pro", "sans-serif"',
						fontWeight: 700,
						textTransform: 'uppercase',
					},
					h5: {
						// @ts-ignore
						fontFamily: '"ff-nuvo-web-pro", "sans-serif"',
						fontWeight: 400,
					},
					h6: {
						// @ts-ignore
						fontFamily: '"ff-nuvo-web-pro", "sans-serif"',
						fontWeight: 400,
					},
					subtitle1: {
						// @ts-ignore
						fontFamily: '"calibri", "sans-serif"',
					},
					subtitle2: {
						// @ts-ignore
						fontFamily: '"calibri", "sans-serif"',
						textTransform: 'uppercase',
					},
					body1: {
						// @ts-ignore
						fontFamily: '"calibri", "sans-serif"',
						fontWeight: 300,
					},
					body2: {
						// @ts-ignore
						fontFamily: '"calibri", "sans-serif"',
					},
					button: {
						// @ts-ignore
						fontFamily: '"calibri", "sans-serif"',
						fontWeight: 400,
					},
					caption: {
						// @ts-ignore
						fontFamily: '"calibri", "sans-serif"',
					},
					overline: {
						// @ts-ignore
						fontFamily: '"calibri", "sans-serif"',
					},
				},
				overrides: {
					// @ts-ignore
					CssBaseline: {
						'@global': {
							'@font-face': ['calibri', 'ff-nuvo-web-pro'],
						},
					},
				},
				TableCell: {
					root: {
						paddingLeft: spacing * 2,
						paddingRight: spacing * 2,
						borderBottom: `${borderWidth}px solid ${borderColor}`,
						[`@media (max-width:  ${sm}px)`]: {
							paddingLeft: spacing,
							paddingRight: spacing,
						},
					},
				},
				ExpansionPanelDetails: {
					root: {
						[`@media (max-width:  ${sm}px)`]: {
							paddingLeft: spacing,
							paddingRight: spacing,
						},
					},
				},
			}),
		[prefersDarkMode],
	)
	theme = responsiveFontSizes(theme)

	return (
		<MuiThemeProvider theme={theme}>
			<CssBaseline />
			<PersistGlobals />
		</MuiThemeProvider>
	)
}
