// @ts-nocheck
import { Box, Card, Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Forbes from '../../data/feeds/Forbes'
import Realtor from '../../data/feeds/Realtor'
import Wellington from '../../data/feeds/Wellington'
import Zillow from '../../data/feeds/Zillow'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		minWidth: 280,
		maxWidth: 420,
		height: '320',
		flexDirection: 'column',
		backgroundColor: theme.palette.background.default,
		boxShadow: 'none',
	},
	zero: {
		paddingLeft: '3vw',
	},
	cards: {
		margin: '5%',
		padding: '2%',
	},
	divider: {
		backgroundColor: theme.palette.secondary.main,
	},
	textbase: {
		gutterBottom: true,
	},
}))

const CardZero = () => {
	const classes = useStyles()
	return (
		<Grid container direction='row' justifyContent='flex-start' alignItems='center'>
			<Card className={classes.root} elevation={0}>
				<Box py={2} letterSpacing={2} className={classes.zero}>
					<Typography className={classes.textbase} variant='h4' component='h2'>
						SIMPLE. QUICK. <br />
						DIRECT.
					</Typography>
					<Divider className={classes.textbase} />
				</Box>
				<Box py={2} letterSpacing={2} className={classes.zero}>
					<Typography variant='h6' component='h3'>
						Partner with a top neighborhood specialist to sell or purchase your next
						home. The Ormica team offers results second to none.
					</Typography>
				</Box>
			</Card>
		</Grid>
	)
}

const responsive = {
	xl: {
		breakpoint: { max: 9999, min: 1921 },
		items: 3,
		partialVisibilityGutter: 40,
	},
	lg: {
		breakpoint: { max: 1920, min: 961 },
		items: 3,
		partialVisibilityGutter: 40,
	},
	med: {
		breakpoint: { max: 960, min: 601 },
		items: 2,
		partialVisibilityGutter: 30,
	},
	sm: {
		breakpoint: { max: 600, min: 0 },
		items: 1,
		partialVisibilityGutter: 30,
	},
}
export default function ParallaxCarousel() {
	const classes = useStyles()
	return (
		<div
			style={{
				paddingTop: '30px',
				paddingBottom: '30px',
				position: 'relative',
			}}>
			<Carousel
				draggable={false}
				showDots={true}
				responsive={responsive}
				partialVisible={true}
				minimumTouchDrag={80}
				renderButtonGroupOutside={false}
				renderDotsOutside={true}>
				<div className={classes.cards}>
					<CardZero />
				</div>
				<div className={classes.cards}>
					<Wellington />
				</div>
				<div className={classes.cards}>
					<Forbes />
				</div>
				<div className={classes.cards}>
					<Zillow />
				</div>
				<div className={classes.cards}>
					<Realtor />
				</div>
			</Carousel>
		</div>
	)
}
