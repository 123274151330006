// @ts-nocheck
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	extraLargeButton: {
		paddingTop: theme.spacing(1.2),
		paddingBottom: theme.spacing(1.2),
		boxShadow: theme.shadows[15],
		[theme.breakpoints.up('xs')]: {
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
		},
		[theme.breakpoints.up('lg')]: {
			paddingTop: theme.spacing(1.5),
			paddingBottom: theme.spacing(1.5),
		},
		width: '100%',
	},
	extraLargeButtonOne: {
		'backgroundColor': 'rgba(0, 95, 156, 0.8)',
		'color': '#FFF',
		'&:hover': {
			backgroundColor: 'rgba(0, 95, 156)',
		},
	},
	extraLargeButtonTwo: {
		'backgroundColor': 'rgba(244, 119, 33, 0.7)',
		'color': '#FFF',
		'&:hover': {
			backgroundColor: 'rgba(244, 119, 33, 0.95)',
		},
	},
}))

export default function ButtonGrid() {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<Grid
				spacing={3}
				container
				direction='row'
				justifyContent='center'
				alignItems='center'>
				<Grid item xs={3} mb={15}>
					<Link to='solutions'>
						<Button
							variant='contained'
							className={`${classes.extraLargeButton} ${classes.extraLargeButtonOne}`}>
							<Typography variant={'h6'}>BUY</Typography>
						</Button>
					</Link>
				</Grid>
				<Grid item xs={1} />
				<Grid item xs={3}>
					<Link to='solutions'>
						<Button
							variant='contained'
							className={`${classes.extraLargeButton} ${classes.extraLargeButtonTwo}`}>
							<Typography variant={'h6'}>SELL</Typography>
						</Button>
					</Link>
				</Grid>
			</Grid>
		</div>
	)
}
