// @ts-nocheck
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Link,
	Snackbar,
	TextField,
	Tooltip,
	Typography,
	withStyles,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import loginCaptcha from '../../utils/loginCaptcha'
import ButtonCircularProgress from '../components/animations/ButtonCircularProgress'
import HighlightedInformation from '../components/animations/HighlightedInformation'
import FormDialog from '../components/dialogs/FormDialog'
import { supabase } from './supabaseClient'

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />
}

const styles = (theme) => ({
	buttonBox: {
		width: '50%',
		margin: 'auto',
	},
	button: {
		'backgroundColor': 'rgba(244, 119, 33, 0.9)',
		'color': '#FFF',
		'&:hover': {
			backgroundColor: 'rgba(244, 119, 33)',
		},
	},
	formControlLabel: {
		marginRight: 0,
	},
})

function Login(props) {
	const { setStatus, theme, classes, onClose, status } = props
	const [loading, setLoading] = useState(false)
	const [hasInvalidEmailError, setHasInvalidEmailError] = useState(false)
	const [hasUnknownError, setHasUnknownError] = useState(false)
	const [loggedIn, setLoggedIn] = useState(false)
	const [email, setEmail] = useState('')

	const handleLogin = async (email) => {
		try {
			setStatus(null)
			setLoading(true)
			loginCaptcha()
			const { err } = await supabase.auth.signIn({ email })
			if (err) throw err
			setLoggedIn(true)
		} catch (err) {
			if (err) {
				setStatus('invalidEmail')
				setHasInvalidEmailError(true)
			}
		} finally {
			setLoading(false)
		}
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		setLoggedIn(false)
		setHasUnknownError(false)
	}

	const longText = `Simply Provide an Email Address, No Password Required. Login Using the Link in the Confirmation Email!`

	return (
		<Fragment>
			<FormDialog
				id='login-form'
				open
				onClose={onClose}
				disabled={loading}
				headline='Login'
				hasCloseIcon
				content={
					<Fragment>
						<Tooltip
							title={longText}
							className={classes.text}
							interactive
							arrow
							placement='top'>
							<TextField
								autoComplete='email'
								required={true}
								id='reg1-email-input'
								type='email'
								name='email'
								label='Email'
								disabled={loading}
								onChange={(e) => setEmail(e.target.value)}
								value={email}
								placeholder='📧'
								fullWidth={true}
								margin='normal'
								variant='outlined'
								color='secondary'
							/>
						</Tooltip>
						<FormControlLabel
							className={classes.formControlLabel}
							control={<Checkbox color='secondary' />}
							label={<Typography variant='body1'>Remember me</Typography>}
						/>
						<Box mt={2}>
							<HighlightedInformation>
								This site is protected by reCAPTCHA and the Google{' '}
								<Link
									color='secondary'
									href='https://policies.google.com/privacy'
									target='_blank'
									rel='noopener noreferrer nofollow'>
									Privacy Policy
								</Link>{' '}
								and{' '}
								<Link
									color='secondary'
									href='https://policies.google.com/terms'
									target='_blank'
									rel='noopener noreferrer nofollow'>
									Terms of Service
								</Link>{' '}
								apply.
							</HighlightedInformation>
						</Box>
					</Fragment>
				}
				actions={
					<Fragment>
						<Box className={classes.buttonBox}>
							<Button
								fullWidth={true}
								type='submit'
								id='submit-login-form'
								variant='contained'
								className={classes.button}
								disabled={loading}
								size='large'
								onClick={(e) => {
									e.preventDefault()
									handleLogin(email)
								}}>
								Login
								{loading && <ButtonCircularProgress color='secondary' />}
							</Button>
						</Box>
						<Box m={2}>
							<div
								id='g_id_onload'
								dataClientId='795388094041-t4budb4dsl4143tdv55scm4uvdd5v0cu.apps.googleusercontent.com'
								dataContext='signin'
								dataUxMode='popup'
								dataLoginUri='https://ormica.com/auth/dashboard/'
								dataNonce=''
								dataAutoSelect='true'
							/>

							<div
								className='g_id_signin'
								dataType='standard'
								dataShape='rectangular'
								dataTheme='filled_black'
								dataText='signin_with'
								dataSize='large'
								dataLocale='en-US'
								dataLogoAlignment='left'
							/>
						</Box>
					</Fragment>
				}
			/>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				open={loggedIn}
				autoHideDuration={6000}
				onClose={handleClose}>
				<Alert onClose={handleClose} severity='success'>
					We have sent you a link so you can complete the login process. Please check
					your email, and remember to check the spam box, too!
				</Alert>
			</Snackbar>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				open={hasUnknownError}
				autoHideDuration={6000}
				onClose={handleClose}>
				<Alert onClose={handleClose} severity='error'>
					Apologies, something went wrong! Please wait a moment and try again.
				</Alert>
			</Snackbar>
		</Fragment>
	)
}

Login.propTypes = {
	theme: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	setStatus: PropTypes.func.isRequired,
	status: PropTypes.string,
}

export default withStyles(styles, { withTheme: true })(Login)
